import React, {useState, useEffect} from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import FadeIn from "../components/FadeIn"
import SlideRightOnAppear from '../components/SlideRightOnAppear'
import epicerie from '../assets/images/epicerie.jpg';

const IndexPage = () => {
  const [loaded, setloaded] = useState(false)


  useEffect(() => {
    if(!loaded && typeof window !== undefined)
    {
      setloaded(true)
    }
    return () => {
      
    }
  }, [])
  return (
    <>
    <Helmet
          title="Epicerie - Le relais de l'avance"
          meta={[
            { name: 'description', content: "Le relais de l'avance - épicerie situé à Saint-Étienne-le-Laus dans les Hautes Alpes 05" },
            { name: 'og:title', content: "Relais de l'avance Restaurant traiteur mariage Hautes Alpes 05"},
            { name: 'og:description', content: "Le relais de l'avance est un Bar - restaurant - traiteur pour mariage avec le concept resto'roul situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05"},
            { name: 'og:type', content: "website"}
          ]}
        >
      </Helmet>
      <Layout activeLink="epicerie">
        <section className="page-section about-heading">
          <div className="container">
            <FadeIn loaded={loaded}>
              <img
                className="img-fluid rounded about-heading-img mb-3 mb-lg-0 shadow1"
                src={epicerie}
                alt="salle restaurant"
              />
            </FadeIn>
            <div className="about-heading-content">
              <div className="row">
                <div className="col-xl-9 col-lg-10 mx-auto">
                  <SlideRightOnAppear loaded={loaded}>
                    <div className="bg-faded rounded p-5 shadow1">
                        <h1 className="section-heading mb-4 section-heading-lower">
                          Le relais de l’avance - L'épicerie
                        </h1>
                        <article>
                          <p>
                            Au fil du temps le <strong>Relais de l’Avance</strong> a vu son activité se centrer sur la restauration et son bar ce qui nous a conduit à 
                            ne garder en épicerie que le strict dépannage tout en proposant des produits régionaux déposés par les producteurs 
                            des départements alpins. <br />
                            Il est à noter également qu’en grand amateur de bières, (ce qui a fait le succès du bar), nous avons mis en place une 
                            cave aux nombreux choix de bières.
                          </p>
                        </article>
                    </div>
                  </SlideRightOnAppear>
                </div>
              </div>
            </div>        
          </div>
        </section>
      </Layout>
    </>
)}

export default IndexPage;
